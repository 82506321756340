import BackgroudImage from "../../../Components/BackgroudImage";
import FormForgotPassword from "../../../Components/Forms/FormForgotPassword";
import FormLogin from "../../../Components/Forms/FormLogin";
import Login from "./ForgotPasswordStyled";

export default function ForgotPassword() {
  const img = "/movies/01.jpg";

  return <FormForgotPassword />;
}
