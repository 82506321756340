import { styled } from "styled-components";

const SectionOneStyled = styled.div`
 
  margin-bottom: 32px;
  flex-direction: column;
  flex: 3;
  margin-top : 24px ;
 
`;
export default SectionOneStyled;
