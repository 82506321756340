import { styled } from "styled-components";

/* interface BackgroudImageStyledProps {
    $backgroundImg?: string;
} */

const FormUserStyled = styled.section`
 .save-profile{
 
    text-align: right;
 }
`;

export default FormUserStyled;
