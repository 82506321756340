import { styled } from "styled-components";

const AppLayoutStyled = styled.main`
 max-width: 100vw;
 min-width: 100vw;
 min-height: 100vh;
 
 
`

export default AppLayoutStyled;